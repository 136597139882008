@import 'variables';
@import 'typography';

/* Define a mixin for common important declarations */
@mixin theme-override {
  &[class] {
    @content;
  }
}

// Theme Root with increased specificity
:host-context(.htds-theme),
body.htds-theme,
.htds-theme {

  // Color scheme
  --htds-primary: #{$htds-blue-2955c} !important;
  --htds-secondary: #{$htds-blue-2718c} !important;
  --htds-gray: #{$htds-gray-4c} !important;

  // Typography
  --htds-font-primary: 'Arial-HTDS', sans-serif !important;
  --htds-font-secondary: 'BitstreamVera', serif !important;

  // Form controls
  --bs-form-control-bg: #{$white} !important;
  --bs-form-control-disabled-bg: var(--htds-gray) !important;
  --bs-form-control-border-color: var(--htds-gray) !important;
  --bs-form-control-focus-border-color: var(--htds-primary) !important;

    // Typography applications
    body {
      font-family: var(--htds-font-primary) !important;
    }

    .htds-logo-text {
      font-family: var(--htds-font-primary) !important;
      
      .s-text {
        font-family: var(--htds-font-secondary) !important;
      }
    }

    // Layout Components
    @include theme-override {
      .container-fluid {
        background: var(--htds-secondary) !important;
      }

      .topbar {
        background-color: var(--htds-secondary) !important;
        color: $white !important;
      }

      .middle {
        background-color: var(--htds-gray) !important;
      }

      .footer {
        background-color: $white !important;
        border-color: var(--htds-gray) !important;
      }
    }

    // Navigation
    @include theme-override {
      .navbar {
        background: var(--htds-primary) !important;
        button:hover {
          background-color: darken($htds-blue-2718c, 10%) !important;
        }
      }

      .navbar {
        .navbar-nav {
          .nav-link {
            color: var(--htds-gray) !important;
            transition: color 0.2s ease-in-out !important;
            
            &:hover {
              color: var(--htds-secondary) !important;
            }
            
            &.active {
              color: var(--htds-primary) !important;
              background-color: var(--htds-secondary) !important;
              border-radius: $htds-border-radius !important;
            }
          }
        }
      }

      .dropdown-menu {
        background-color: var(--htds-secondary) !important;
        
        button {
          color: var(--htds-gray) !important;
          background-color: var(--htds-secondary) !important;
          &:hover {
            color: $white !important;
            background-color: darken($htds-blue-2955c, 10%) !important;
          }
        }
      }
    }

    // Form Controls
    @include theme-override {
      .form-control,
      .form-select {
        font-size: 1rem !important;
        transition: all 0.2s ease-in-out !important;
        background-color: var(--bs-form-control-bg) !important;

        &::placeholder {
          color: var(--htds-gray) !important;
          opacity: 0.75 !important;
        }

        &:hover:not(:disabled):not(:focus) {
          border-color: var(--htds-primary) !important;
        }

        &:focus {
          border-color: var(--htds-primary) !important;
          box-shadow: 0 0 0 0.25rem rgba($htds-blue-2718c, 0.25) !important;
        }

        &:disabled {
          background-color: var(--bs-form-control-disabled-bg) !important;
          opacity: 0.65 !important;
          cursor: not-allowed !important;
        }

        &:read-only {
          background-color: rgba($htds-gray-4c, 0.1) !important;
          border-color: var(--htds-gray) !important;
          
          &:focus {
            box-shadow: none !important;
          }
        }

        &.is-valid {
          border-color: $success !important;
          padding-right: calc(1.5em + 0.75rem) !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
          background-repeat: no-repeat !important;
          background-position: right calc(0.375em + 0.1875rem) center !important;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;

          &:focus {
            border-color: $success !important;
            box-shadow: 0 0 0 0.25rem rgba($success, 0.25) !important;
          }
        }

        &.is-invalid {
          border-color: $danger !important;
          padding-right: calc(1.5em + 0.75rem) !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
          background-repeat: no-repeat !important;
          background-position: right calc(0.375em + 0.1875rem) center !important;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;

          &:focus {
            border-color: $danger !important;
            box-shadow: 0 0 0 0.25rem rgba($danger, 0.25) !important;
          }
        }
      }

      // Form Select Specific
      .form-select {
        appearance: none !important;
        padding-right: 2.25rem !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 16px 12px !important;

        &[multiple],
        &[size]:not([size="1"]) {
          padding-right: 1rem !important;
          background-image: none !important;
          height: auto !important;
        }

        &:disabled {
          background-color: var(--bs-form-control-disabled-bg) !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
        }
      }

      // Form Labels
      .form-label {
        font-weight: 350 !important;
        margin-bottom: 0.5rem !important;
        color: var(--htds-primary) !important;
        
        &:after {
          content: ' *' !important;
          color: var(--htds-primary) !important;
        }

        &.optional:after,
        &.id:after {
          content: none !important;
        }
      }
    }

    // Form Check Inputs
    @include theme-override {
      .form-check-input {
        &:checked {
          background-color: var(--htds-primary) !important;
          border-color: var(--htds-primary) !important;
        }

        &:focus {
          border-color: var(--htds-primary) !important;
          box-shadow: var(--bs-form-control-focus-box-shadow) !important;
        }
      }

      // Range inputs
      input[type="range"] {
        &::-webkit-slider-thumb {
          background: var(--htds-secondary) !important;
        }
        
        &::-moz-range-thumb {
          background: var(--htds-secondary) !important;
        }
        
        &::-ms-thumb {
          background: var(--htds-secondary) !important;
        }
      }
    }

    // Button Styles
    @include theme-override {
      .btn {
        transition: all 0.2s ease-in-out !important;
      }

      .btn-primary {
        background-color: var(--htds-primary) !important;
        border-color: var(--htds-primary) !important;

        &:hover {
          background-color: darken($htds-blue-2955c, 10%) !important;
          border-color: darken($htds-blue-2955c, 10%) !important;
        }
      }

      .btn-secondary {
        background-color: var(--htds-secondary) !important;
        border-color: var(--htds-secondary) !important;

        &:hover {
          background-color: darken($htds-blue-2955c, 10%) !important;
          border-color: darken($htds-blue-2955c, 10%) !important;
        }
      }

      .btn-light {
        background-color: var(--htds-gray) !important;
        border-color: var(--htds-gray) !important;
        color: var(--htds-secondary) !important;

        &:hover {
          background-color: darken($htds-gray-4c, 10%) !important;
          border-color: darken($htds-gray-4c, 10%) !important;
          color: var(--htds-primary) !important;
        }
      }

      .btn-outline-primary {
        color: var(--htds-primary) !important;
        border-color: var(--htds-primary) !important;
        background-color: transparent !important;

        &:hover {
          color: $white !important;
          background-color: var(--htds-primary) !important;
          border-color: var(--htds-primary) !important;
        }

        &:disabled {
          color: var(--htds-gray) !important;
          border-color: var(--htds-gray) !important;
          background-color: transparent !important;
          opacity: 0.65 !important;
        }

        &:focus {
          box-shadow: var(--bs-form-control-focus-box-shadow) !important;
        }
      }

      .btn-outline-secondary {
        color: var(--htds-secondary) !important;
        border-color: var(--htds-secondary) !important;
        background-color: transparent !important;

        &:hover {
          color: $white !important;
          background-color: var(--htds-secondary) !important;
          border-color: var(--htds-secondary) !important;
        }

        &:disabled {
          color: var(--htds-gray) !important;
          border-color: var(--htds-gray) !important;
          background-color: transparent !important;
          opacity: 0.65 !important;
        }
      }

      // Button block styles
      .btn-block {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        margin-top: 1.5rem !important;
        
        button {
          width: 48% !important;
          height: 48px !important;
          
          &.btn-primary {
            background-color: var(--htds-primary) !important;
            border-color: var(--htds-primary) !important;
            
            &:hover {
              background-color: darken($htds-blue-2955c, 10%) !important;
              border-color: darken($htds-blue-2955c, 10%) !important;
            }
            
            &:disabled {
              background-color: var(--htds-gray) !important;
              border-color: var(--htds-gray) !important;
              opacity: 0.65 !important;
            }
          }
          
          &.btn-outline-primary {
            color: var(--htds-primary) !important;
            border-color: var(--htds-primary) !important;
            background-color: transparent !important;
            
            &:hover {
              color: $white !important;
              background-color: var(--htds-primary) !important;
              border-color: var(--htds-primary) !important;
            }
            
            &:disabled {
              color: var(--htds-gray) !important;
              border-color: var(--htds-gray) !important;
              opacity: 0.65 !important;
            }
          }
        }
      }
    }

    // Card Components
    @include theme-override {
      .card,
      .info-card {
        background-color: $white !important;
        border-color: var(--htds-gray) !important;
        box-shadow: var(--htds-box-shadow) !important;
        border-radius: $htds-border-radius !important;
        transition: box-shadow 0.2s ease-in-out !important;

        h1, h2, h3, h4 {
          color: var(--htds-secondary) !important;
        }
      }

      .items-detected-card {
        background-color: var(--htds-gray) !important;
      }

      .timeframe-select-card:not(:checked) {
        border-color: var(--htds-gray) !important;
        color: var(--htds-secondary) !important;
      }
    }

    // Modal styles
    @include theme-override {
      .modal-dialog.htds-theme {
        .modal-content {
          border-radius: $htds-border-radius !important;
          border: 1px solid var(--htds-gray) !important;
          box-shadow: var(--htds-box-shadow) !important;
          
          .modal-header {
            padding: 1.5rem !important;
            border-bottom: none !important;
            
            .modal-title {
              h4 {
                color: var(--htds-primary) !important;
                margin-bottom: 0.5rem !important;
              }
              
              small {
                color: var(--htds-gray) !important;
                font-weight: bold !important;
              }
            }
              
            .btn-close {
              padding: 0.5rem !important;
              margin: 0 !important;
              
              &:focus {
                box-shadow: 0 0 0 0.25rem rgba($htds-blue-2718c, 0.25) !important;
              }
            }
          }
          
          .modal-body {
            padding: 0 1.5rem 1.5rem !important;
            
            .form-group {
              margin-bottom: 1.5rem !important;
            }
              
            .form-control,
            .form-select {
              &:focus {
                border-color: var(--htds-primary) !important;
                box-shadow: 0 0 0 0.25rem rgba($htds-blue-2718c, 0.25) !important;
              }
            }
          }
        }
      }
    }

     // Link Styles
     @include theme-override {
      .reset-link,
      .dashboard-link {
        color: var(--htds-primary) !important;
        transition: color 0.2s ease-in-out !important;
        
        &:hover {
          color: darken($htds-blue-2955c, 10%) !important;
        }
      }

      .start-analysing-link {
        color: var(--htds-gray) !important;
        
        &:hover {
          color: $white !important;
        }
      }

      .notMatchLinkStyle {
        color: var(--htds-gray) !important;
      }

      // Generic Link Styles
      a:not(.nav-link):not(.reset-link):not(.dashboard-link):not(.start-analysing-link):not(.notMatchLinkStyle) {
        color: var(--htds-primary) !important;
        transition: color 0.2s ease-in-out !important;
        
        &:hover {
          color: darken($htds-blue-2955c, 10%) !important;
        }
      }
    }

    // Badge
    @include theme-override {
      .bg-info {
        background-color: var(--htds-secondary) !important;
        color: $white !important;
      }
    }

    // Avatar Component
    @include theme-override {
      .avatar-initials {
        transition: all 0.2s ease-in-out !important;

        &.first {
          background-color: var(--htds-gray) !important;
          color: var(--htds-secondary) !important;
        }

        &.second {
          background-color: var(--htds-secondary) !important;
          color: $white !important;
        }
      }
    }

    // File Upload Components
    @include theme-override {
      .drag-and-drop-container {
        border-color: var(--htds-gray) !important;
        background-color: $white !important;
        transition: border-color 0.2s ease-in-out !important;
        
        &:hover {
          border-color: var(--htds-secondary) !important;
        }
      }

      .fileover {
        border: dashed 2px var(--htds-primary) !important;
      }

      .files-list {
        .single-file {
          transition: all 0.2s ease-in-out !important;
          
          .name {
            color: var(--htds-secondary) !important;
          }

          &:hover {
            background-color: var(--htds-gray) !important;

            .name {
              color: var(--htds-primary) !important;
            }
          }

          &.error {
            background-color: var(--htds-gray) !important;

            .name {
              color: $danger !important;
            }
          }
        }
      }
    }

    // Image Components
    @include theme-override {
      .img-thumbnail {
        border-color: var(--htds-gray) !important;
        transition: border-color 0.2s ease-in-out !important;
        
        &:hover {
          border-color: var(--htds-secondary) !important;
        }
      }
    }

    // Custom Day Picker
    @include theme-override {
      .custom-day {
        &.focused {
          background-color: var(--htds-gray) !important;
        }

        &.range,
        &:hover {
          background-color: var(--htds-primary) !important;
          color: $white !important;
        }

        &.faded {
          background-color: rgba($htds-blue-2955c, 0.5) !important;
        }
      }
    }

    // Carousel Component
    @include theme-override {
      .carousel {
        &-control {
          &-prev,
          &-next {
            background-color: rgba($htds-blue-2718c, 0.5) !important;
            transition: background-color 0.2s ease-in-out !important;
            
            &:hover {
              background-color: rgba($htds-blue-2718c, 0.8) !important;
            }
          }
        }

        &-indicators {
          button {
            background-color: var(--htds-gray) !important;
            border-color: var(--htds-secondary) !important;
            transition: background-color 0.2s ease-in-out !important;

            &.active {
              background-color: var(--htds-secondary) !important;
            }

            &:hover {
              background-color: darken($htds-gray-4c, 10%) !important;
            }
          }
        }
      }
    }

    // Typography
    @include theme-override {
      ul > li > span,
      small {
        color: var(--htds-secondary) !important;
      }
    }

    // Time Frame Select
    @include theme-override {
      .timeframe-select:not(:checked) {
        border-color: var(--htds-secondary) !important;
        color: $white !important;
      }
    }

    // Additional States and Overrides
    @include theme-override {
      .focused {
        background-color: var(--htds-gray) !important;
      }

      .range {
        background-color: var(--htds-primary) !important;
        color: $white !important;
      }

      .faded {
        background-color: rgba($htds-blue-2955c, 0.5) !important;
      }
    }
    
}