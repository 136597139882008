// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

@import "bootstrap/scss/functions";

// HTDS Theme Colors
$htds-blue-2718c: #7D89B3;  // RGB(80, 135, 199) - CMYK(70, 40, 0, 0)
$htds-blue-2955c: #00558C;  // RGB(0, 85, 140) - CMYK(98, 43, 0, 35)
$htds-gray-4c: #d0d8e1;     // RGB(213, 214, 215) - CMYK(0, 0, 0, 22)

// Default theme colors with conditional overrides
$primary: if(variable-exists(htds-theme), $htds-blue-2955c, #ca4300);
$secondary: if(variable-exists(htds-theme), $htds-blue-2718c, #3c515b);
$cobalt: if(variable-exists(htds-theme), $htds-blue-2955c, #348df7);
$success: #57c785;
$info: #49738b;
$warning: #faa61a;
$danger: #f5262e;
$light: #f6f9fc;
$dark: #252525;

$blue: #0d6efd;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #57c785;
$gray: #999999;
$charcoal: #3c515b;

// HTDS specific variables
$htds-border-radius: 4px;
$htds-box-shadow: 0 2px 4px rgba($htds-blue-2955c, 0.1);
$htds-transition: all 0.3s ease-in-out;

// Component specific variables
$htds-input-height: 40px;
$htds-btn-padding: 0.5rem 1rem;
$htds-card-border-radius: $htds-border-radius;

$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f2f2f2 !default;
$gray-300: #dcdcdc !default;
$gray-400: #bebebe !default;
$gray-500: $gray !default;
$gray-600: #666666 !default;
$gray-700: #555555 !default;
$gray-800: #333333 !default;
$gray-900: #252525 !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: #def3e9 !default;
$green-200: #ceecdb !default;
$green-300: #afe2c6 !default;
$green-400: #83d3a4 !default;
$green-500: $green !default;
$green-600: #39a45e !default;
$green-700: #328b4e !default;
$green-800: #246436 !default;
$green-900: #1a4825 !default;

$cobalt-100: #e4ecfe !default;
$cobalt-200: #c8dafd !default;
$cobalt-300: #86b3fa !default;
$cobalt-400: #599cf9 !default;
$cobalt-500: $cobalt !default;
$cobalt-600: #0974e7 !default;
$cobalt-700: #0869c4 !default;
$cobalt-800: #064f8a !default;
$cobalt-900: #032a45 !default;

$charcoal-100: #eaeef2 !default;
$charcoal-200: #c7d2da !default;
$charcoal-300: #87a2b4 !default;
$charcoal-400: #597888 !default;
$charcoal-500: $charcoal !default;
$charcoal-600: #304249 !default;
$charcoal-700: #233034 !default;
$charcoal-800: #182124 !default;
$charcoal-900: #12191b !default;

//customizing input fields
$input-bg: $gray-100;
$input-border-color: $gray-400;
$input-border-width: 1px;
$input-border-radius: 4.8px;

// override the modal size to correspond to the design
$modal-lg: 600px;
$modal-inner-padding: 25px;

//override the divider color for scan details page
$breadcrumb-divider-color: $gray-400;

$link-color: $dark;
$hover-color: $primary;
$visited-color: #551a8b;

$form-range-track-bg: $gray-300;
$form-range-thumb-bg: $gray-800;

$caret-width: 0em;

$alert-margin-bottom: 0em;

$grid-columns: 12;
$grid-gutter-width: 24px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 760px,
  lg: 1100px,
  xl: 1280px,
  xxl: 1440px,
);
// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
