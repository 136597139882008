// HTDS Typography
@font-face {
  font-family: 'Arial-HTDS';
  src: local('Arial');
}

@font-face {
  font-family: 'BitstreamVera';
  src: url('../../assets/fonts/Vera.ttf') format('truetype');
}
  
.htds-theme {
  --htds-font-size-base: 1rem;
  --htds-line-height-base: 1.5;
  
  h1, .h1 { font-size: calc(var(--htds-font-size-base) * 2.5); }
  h2, .h2 { font-size: calc(var(--htds-font-size-base) * 2); }
  h3, .h3 { font-size: calc(var(--htds-font-size-base) * 1.75); }
  h4, .h4 { font-size: calc(var(--htds-font-size-base) * 1.5); }
}

  // Typography utility classes
  .htds-font-arial {
    font-family: 'Arial-HTDS', sans-serif;
  }
  
  .htds-font-bitstream {
    font-family: 'BitstreamVera', serif;
  }
  
  // Special handling for HTDS logo
  .htds-logo {
    font-family: 'Arial-HTDS', sans-serif;
    
    .s-letter {
      font-family: 'BitstreamVera', serif;
    }
  }