/* You can add global styles to this file, and also import other style files */ //

@import 'variables';
@import 'maps';
@import 'typography';
@import 'utilities';
@import 'common';
// Theme styles
@import 'assets/scss/htds-theme';


html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
