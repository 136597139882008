// 5. Include remainder of required parts
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

//Generating bg and text color for all base Colors
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value !important;
  }

  .text-#{$color} {
    color: $value !important;
  }
}
@each $key, $value in $grays {
  .bg-gray-#{$key} {
    background-color: $value !important;
  }

  .text-gray-#{$key} {
    color: $value !important;
  }
}

@each $key, $value in $cobalts {
  .bg-cobalt-#{$key} {
    background-color: $value !important;
  }

  .text-cobalt-#{$key} {
    color: $value !important;
  }
}

@each $key, $value in $charcoals {
  .bg-charcoal-#{$key} {
    background-color: $value !important;
  }

  .text-charcoal-#{$key} {
    color: $value !important;
  }
}

@each $key, $value in $htds-colors {
  .htds-#{$key} {
    color: $value !important;
  }

  .htds-bg-#{$key} {
    background-color: $value !important;
  }
}
//Generating css classes for all shades of all available colours

$all-colors: map-merge-multiple($blues, $reds, $oranges, $yellows, $greens);

$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              ($all-colors)
            ),
        )
      ),
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "background-color"), "values"),
              ($all-colors)
            ),
        )
      ),
      "htds-colors": (
        property: color,
        class: htds,
        values: $htds-colors
      ),
      "htds-bg": (
        property: background-color,
        class: htds-bg,
        values: $htds-colors
      )
  )
);