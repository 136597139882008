//********sorting style*********//
@import "variables.scss";

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: baseline;
}

th[sortable].asc > span {
  & > i.bi-caret-up-fill {
    color: $gray-600 !important;
  }
  & > i.bi-caret-down-fill {
    color: $gray-300 !important;
  }
}

th[sortable].desc > span {
  & > i.bi-caret-down-fill {
    color: $gray-600 !important;
  }
  & > i.bi-caret-up-fill {
    color: $gray-300 !important;
  }
}

.span-sorting-icons {
  display: inline-block;
  vertical-align: middle;
  & i {
    font-size: 0.6rem;
    display: block !important;
    color: $gray-300;
  }
}
/*****table styles****/
table.table {
  border: 1px solid $gray-300;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
}

td {
  vertical-align: middle;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select option {
  color: black;
}

.alert-danger {
  background-color: $red-100 !important;
  border-color: $red-200 !important;
  color: $red-700 !important;
}
